export const LanguageOptions = [
  { value: 'en', label: 'English' },
  { value: 'zh-CN', label: '简体中文' },
  { value: 'ko', label: '한국인' },
  { value: 'es', label: 'Español' }
]

export const DefaultLauageOption = { value: 'en', label: 'English' }

export const CoinOptions = [
  { value: 'bitcoin', label: "Bitcoin(BTC)" },
  { value: 'ethereum', label: "Ethereum(ETH)" },
  { value: 'binance-coin', label: "Binance(BNB)" },
 
]

export const DefaultCoinOption = { value: 'bitcoin', label: "Bitcoin(btc)" }

export const RequestState = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
}

export const LoadingState = {
  'INITIAL': 0,
  'LOADING': 1,
  'LOADED': 2
}

export const ModelOptions = [
  { value: 'llm', label: 'Deep Search (Llama3 on EdgeCloud based)' },
  { value: 'deep_search', label: 'Deep Search (GPT4 based)' },
  { value: 'chatgpt', label: 'ChatGPT' },
  { value: 'llama3', label: 'Llama3 on EdgeCloud' },

]
